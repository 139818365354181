import React from "react"
import Layout from "../components/layout"
import Group from "../components/Group"
import SEO from "../components/seo"
import { Routing, getTitle, getId } from "../utils/routing"
import ProjectName from "../components/ProjectName"
import LinkMailToSupport from "../components/LinkMailToSupport"
const RC = Routing.RESPONSIBLE_GAMBLING.sub
const title = Routing.RESPONSIBLE_GAMBLING.title

const ResponsibleGambling = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={title} />
      <h1>
        <ProjectName /> {title}
      </h1>
      <p>
        <ProjectName /> only accepts members of legal gambling age (18). We want
        members to enjoy gambling. We do not want anyone to suffer any hardship
        or cause his or her families or friends to suffer as a result of out of
        control gambling.
      </p>
      <p>
        <ProjectName /> will help anyone who believes they are developing a
        gambling problem. We will suspend accounts or impose limits on customers
        we believe to be showing sign of problem gambling. <ProjectName />,
        however, cannot be held responsible for the actions of its members.
      </p>
      <Group id={getId(RC.RESPONSIBLE_GAMING_TIPS)}>
        <h2> {getTitle(RC.RESPONSIBLE_GAMING_TIPS)}</h2>
        <p>
          For many people gambling is exciting, fun and entertaining, but if you
          feel you are developing a problem with gambling, here are some
          suggestions on how you can regain control:
        </p>
        <p>
          {" "}
          Decide on a loss limit ahead of time and stick to it. You can set this
          on the account limits page.
        </p>
        <p> Never borrow money to gamble.</p>
        <p> Balance gambling with other activities.</p>
        <p> Don't gamble when highly stressed, depressed or intoxicated.</p>
        <p>
          {" "}
          Only gamble with money set aside for entertainment, never with money
          for everyday expenses.
        </p>
      </Group>
      <Group id={getId(RC.HOW_TO_REALIZE_YOU_HAVE_A_PROBLEM)}>
        <h2> {getTitle(RC.HOW_TO_REALIZE_YOU_HAVE_A_PROBLEM)}</h2>
        <p>
          If you are leaving your family alone for long periods of time in order
          to gamble. If you argue frequently with family or friends about
          spending too much time and money on gambling and find that you cannot
          stop or limit yourself.
        </p>
        <p>
          {" "}
          If you are gambling money needed for housing, education, health care,
          food, clothing or utilities.
        </p>
        <p>
          When you are with your family, at work or at social events, you find
          that you are constantly thinking about your next trade.
        </p>
        <p>
          {" "}
          There are many organizations that may help you to control your
          problem, such as{" "}
          <a href="http://gamblersanonymous.org" target="_blank">
            www.gamblersanonymous.org
          </a>
          .
        </p>
      </Group>

      <Group id={getId(RC.TAKE_ACTION)}>
        <h2>{getTitle(RC.TAKE_ACTION)}</h2>
        <p>
          If you think you have a gambling problem, you should seek help and
          suspend your account until you resolve your issues. For your benefit,
          you will not be allowed to rejoin <ProjectName /> for a minimum of six
          months.
        </p>
        <p>
          If you have any questions you can get in touch with <ProjectName />{" "}
          through our contact page, and get in touch with us at{" "}
          <LinkMailToSupport />.
        </p>
      </Group>
    </Layout>
  )
}

export default ResponsibleGambling
